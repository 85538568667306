
const initialState = { show: false, message: '' };

const alertReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_ALERT':
      return {
        loading: false,
        show: action?.show ?? state.show,
        message: action?.message ?? state.message,
      };
    case 'UNSET_ALERT':
      return initialState;
    default:
      return state;
  }
};

export default alertReducer;