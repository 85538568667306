import { combineReducers } from 'redux';

import loaderReducer from './loaderReducer';
import alertReducer from './alertReducer';
import userReducer from './userReducer';

export const reducers = combineReducers({
  loaderState: loaderReducer,
  alertState: alertReducer,
  userState: userReducer,
});

export type RootState = ReturnType<typeof reducers>;
