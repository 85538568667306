import { useEffect } from "react";
import Amplify, { Auth, Hub } from 'aws-amplify';
import { useDispatch } from "react-redux";
import { signInUser, signOutUser } from '../store/actions/userAction';
import { startLoader, endLoader } from '../store/actions/loaderAction';
import { setAlert } from "../store/actions/alertAction";

type User = { username: string, password: string };

type AuthMethods =
  | "currentAuthenticatedUser"
  | "signIn"
  | "signOut"
  | "signUp"
  | "confirmSignUp";

const useAuth = () => {

  const dispatch = useDispatch();
  
  useEffect(() => {
    Amplify.configure({
      Auth: {
        region: process.env.REACT_APP_REGION,
        identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
        userPoolId: process.env.REACT_APP_USER_POOL_ID,
        userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
      }
    });
  }, []);

  useEffect(() => {
    const updateUser = async () => {
      await getCurrentUser();
    };
    Hub.listen("auth", updateUser); // listen for login/signup events
    updateUser(); // check manually the first time because we won't get a Hub event
    return () => Hub.remove("auth", updateUser);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const callAuth = async ({
    method,
    params = [], 
    callback = () => {}, 
    customErrorMsg = '',
  } : {
    method: AuthMethods,
    params?: Array<object | string>,
    callback: Function,
    customErrorMsg?: string
  }) => {
    try {
      dispatch(startLoader());
      // @ts-ignore
      const result = await Auth[method](...params);
      dispatch(endLoader());
      callback(result);
    } catch(err) {
      dispatch(endLoader());
      if (customErrorMsg) dispatch(setAlert(customErrorMsg));
      console.log(err);
    };
  }

  const getCurrentUser = async () => {
    await callAuth({
      method: 'currentAuthenticatedUser',
      callback: (currentUser: object) => {
        dispatch(signInUser(currentUser));
      }
    });
  };

  const signIn = async (username: string, password: string, callback: Function) => {
    await callAuth({
      method: 'signIn', 
      params: [{ username, password }], 
      callback, 
      customErrorMsg: 'Incorrect username or password',
    });
  }

  const signOut = async () => {
    await callAuth({
      method: 'signOut',
      callback: () => {
        dispatch(signOutUser());
      }
    });
  }

  const signUp = async (user: User, callback: Function) => {
    await callAuth({
      method: 'signUp',
      params: [{ 
        username: user.username, 
        password: user.password,
      }],
      callback,
      customErrorMsg: 'Invalid e-mail or password'
    })
  }

  const confirmSignUp = async (email: string, code: string, callback: Function) => {
    await callAuth({
      method: 'confirmSignUp',
      params: [email, code],
      callback,
    })
  }

  const getToken = async () => {
    try {
      const session = await Auth.currentSession();
      let accessToken = session.getAccessToken();
      return accessToken.getJwtToken();
    } catch(err) {
      console.log(err);
      return '';
    };
  }

  return { signIn, signOut, signUp, confirmSignUp, getToken };
};

export default useAuth;