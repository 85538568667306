import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import {
  Modal,
  Button,
  Card,
  CardActionArea,
  CardContent,
} from '@mui/material';
import { unsetAlert } from "../../store/actions/alertAction";

const Alert = () => {

  const dispatch = useDispatch();
  const [message, setMessage] = useState('');
  const [show, setShow] = useState(false);

  const alert = useSelector((state) => state.alertState);

  useEffect(() => {
    if (alert && typeof alert !== 'undefined') {
      setShow(alert.show);
      setMessage(alert.message);
    }
  }, [alert]);

  const handleClose = () => {
    dispatch(unsetAlert());
  };

  return (
    <Modal open={show} onClose={handleClose}>
      <Card>
        <CardContent style={{ textAlign: 'center' }}>
          { message }
        </CardContent>
        <CardActionArea>
          <Button
            onClick={handleClose}
            fullWidth
          >
            Close
          </Button>
        </CardActionArea>
      </Card>
    </Modal>
  );
}

export default Alert;