import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { Fab } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { Link } from 'react-router-dom';

import PrivateRoutes from './components/authentication/PrivateRoutes';
import Footer from "./components/footer/Footer";

const SignUp = React.lazy(() => import('./components/authentication/SignUp'));
const SignIn = React.lazy(() => import('./components/authentication/SignIn'));
const Profile = React.lazy(() => import('./components/profile/Profile'));
const CreateExpense = React.lazy(() => import('./components/expenses/create/CreateExpense'));
const UpdateExpense = React.lazy(() => import('./components/expenses/update/UpdateExpense'));
const ListExpenses = React.lazy(() => import('./components/expenses/list/ListExpenses'));

const Router = () => {

  return (
    <React.Suspense fallback={<span>Loading...</span>}>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Navigate replace to="/signin" />} />
        <Route path="/signin" element={<SignIn />} />
        <Route path="/signup" element={<SignUp />} />
        <Route
          path="/new"
          element={
            <PrivateRoutes>
              <CreateExpense />
            </PrivateRoutes>
          }
        />
        <Route
          path="/stat"
          element={
            <PrivateRoutes>
              Statistics
            </PrivateRoutes>
          }
        />
        <Route
          path="/history"
          element={
            <PrivateRoutes>
              <ListExpenses />
            </PrivateRoutes>
          }
        />
        <Route
          path="/profile"
          element={
            <PrivateRoutes>
              <Profile />
            </PrivateRoutes>
          }
        />
        <Route
          path="/update"
          element={
            <PrivateRoutes>
              <UpdateExpense />
            </PrivateRoutes>
          }
        />
        <Route path="*" element={<Navigate replace to="/" />} />
      </Routes>
      <Fab
        component={Link}
        to="/new"
        color="primary"
        aria-label="Add"
        sx={{
          position: 'absolute',
          bottom: 76,
          right: 16,
        }}
      >
        <AddIcon />
      </Fab>
      <Footer />
    </BrowserRouter>
    </React.Suspense>
  );
}

export default Router;