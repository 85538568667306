import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import {
  Modal,
  Backdrop,
  CircularProgress
} from '@mui/material';

const Loader = () => {

  const loader = useSelector((state) => state.loaderState);
  const [show, setShow] = useState(true);
  const handleShow = (show) => setShow(show);

  useEffect(() => {
    handleShow(loader.loading);
  }, [loader.loading]);
  
  return (
      <Modal
        size="sm"
        backdrop="static"
        open={show}
      >
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={show}
        >
          <CircularProgress color="inherit" />
          <span className="visually-hidden">
            &nbsp;&nbsp;&nbsp;&nbsp;
            Loading...
          </span>
        </Backdrop>
      </Modal>
  );
}

export default Loader;