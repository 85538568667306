import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

function PrivateRoutes(props) {

  const user = useSelector((state) => state.userState);
  const navigate = useNavigate();
  
  useEffect(() => {
    if (Object.keys(user).length === 0) {
      navigate('/signin', { replace: true });
    }
  }, [user, navigate]);
  
  return props.children;
}

export default PrivateRoutes;
