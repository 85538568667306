import React from "react";
import { useSelector } from "react-redux";

import ErrorBoundary from './components/errorBoundary/ErrorBoundary';
import useHttpInterceptor from './hooks/useHttpInterceptor';
import Router from "./Router";
import Loader from './components/common/Loader';
import Alert from './components/common/Alert';
import ProfileNavbar from './components/profile/ProfileNavbar';
import { RootState } from "./store/reducers";
import { CognitoUser } from "./types/CognitoUser";
import useAuth from './hooks/useAuth';

import './App.css';

function App() {
  useHttpInterceptor();

  useAuth();
  const user: CognitoUser = useSelector((state: RootState) => state.userState);

  return (
    <div className="App">
      { user?.signInUserSession?.accessToken?.jwtToken ? <>  
        <ProfileNavbar />
        <Loader />
        <Alert />
        <ErrorBoundary>
          <Router />
        </ErrorBoundary>
      </> : <div>Loading...</div> }
    </div>
  );
}

export default App;
