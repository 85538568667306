import { useEffect } from 'react';
import axios from 'axios';
import { useDispatch } from "react-redux";
import { startLoader, endLoader } from './../store/actions/loaderAction';

const useHttpInterceptor = () => {

  const dispatch = useDispatch();

  useEffect(() => {
    axios.interceptors.request.use((config) => {
      dispatch(startLoader());
      const token = window.localStorage.token;
      if (token) {
        config.headers.Authorization = `token ${token}`;
      }
      return config;
    }, (error) => {
      return Promise.reject(error);
    });
    axios.interceptors.response.use((response) => {
      dispatch(endLoader());
      return response;
    }, (error) => {
      return Promise.reject(error);
    });
  }, [dispatch]);

  return {};
}

export default useHttpInterceptor;