import {
  AppBar,
  // Button,
  Container,
  Toolbar,
  Typography
} from '@mui/material';
// import useAuth from './../../hooks/useAuth';

const ProfileNavbar = () => {

  // const auth = useAuth();

  return (
    <Container style={{ marginBottom: "80px" }}>
      <AppBar bg="dark" variant="dark">
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Budget Forecast
          </Typography>
          {/* <Navbar.Collapse className="justify-content-end"> */}
          {/* <Button variant="outline-warning" onClick={auth.signOut}>Sign out</Button> */}
          {/* </Navbar.Collapse> */}
        </Toolbar>
      </AppBar>
    </Container>
  )

}

export default ProfileNavbar;