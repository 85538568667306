
const initialState = { 
  loading: false, 
  success: true, 
  message: '' 
};

const loaderReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'START_LOADER':
      return {
        ...state,
        loading: true,
      };
    case 'END_LOADER':
      return {
        loading: false,
        success: action?.success ?? state.success,
        message: action?.message ?? state.message,
      };
    default:
      return state;
  }
};

export default loaderReducer;