import React, { useState } from 'react';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import PersonIcon from '@mui/icons-material/Person';
import AddchartIcon from '@mui/icons-material/Addchart';
import HistoryIcon from '@mui/icons-material/History';
import Paper from '@mui/material/Paper';
import { Link } from 'react-router-dom';

export default function Footer() {
  const [value, setValue] = useState(0);

  return (
    <Box sx={{ pb: 7 }}>
      <CssBaseline />
      <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
        <BottomNavigation
          showLabels
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
        >
          <BottomNavigationAction
            component={Link}
            to="/stat"
            value="stat"
            label="Stat"
            icon={<AddchartIcon />}
          />
          <BottomNavigationAction
            component={Link}
            to="/history"
            value="history"
            label="History"
            icon={<HistoryIcon />}
          />
          <BottomNavigationAction
            component={Link}
            to="/profile"
            value="profile"
            label="Profile"
            icon={<PersonIcon />}
          />
        </BottomNavigation>
      </Paper>
    </Box>
  );
}
