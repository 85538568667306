import React from 'react';

class ErrorBoundary extends React.Component {

  state = {
    hasError: false,
    errorMessage: '',
  }

  componentDidCatch = (error, info) => {
    this.setState({ hasError: true, errorMessage: info });
  }

  render() {
    if (this.state.hasError) {
      return <React.Fragment>
          <h1>Service Unavailable</h1>
          <div>{this.state.errorMessage}</div>
        </React.Fragment>;
    } else {
      return this.props.children;
    }
  }

}

export default ErrorBoundary;